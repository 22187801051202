/**
 * Created by avim on 12/27/15.
 */
define([
    'prop-types',
    'lodash',
    'componentsCore',
    'backgroundCommon',
    'components',
    'santa-components'
], function (
    PropTypes,
    _,
    componentsCore,
    backgroundCommon,
    components,
    santaComponents
) {
    'use strict';

    const mediaCommon = components.mediaCommon;

    const getRootStyle = (rootStyle, isMeshLayoutMechanism) => {
        rootStyle = rootStyle || {};

        return isMeshLayoutMechanism ?
            _.assign({}, rootStyle, {display: 'flex'}) :
            rootStyle;
    };

    const getContainerStyle = containerStyle => {
        if (_.isEmpty(containerStyle.borderWidth)) {
            return containerStyle;
        }

        const borderWidth = parseInt(containerStyle.borderWidth, 10);
        return _.assign({}, containerStyle, {'margin': `-${borderWidth}px`});
    };

    /**
     * Create a new MediaContainer component definition
     * @param options
     * @returns {{displayName: (*|string), mixins: [*,*,*], propTypes: {style: *, compDesign, rootStyle: *, bgStyle: *, inlineStyle: *}, statics: {behaviors: (exports.behaviorsAPI|{play, pause, stop, setVolume, mute, unMute, seek}|{play, pause, stop})}, getDefaultSkinName: components.MediaContainer.getDefaultSkinName, getSkinProperties: components.MediaContainer.getSkinProperties}}
     */
    function createMediaContainer(options) {
        options = options || {};
        const mixins = [
            mediaCommon.mediaLogicMixins.fill,
            componentsCore.mixins.skinBasedComp,
            componentsCore.mixins.createChildComponentMixin,
            santaComponents.mixins.inlineContentMixin
        ];
        if (options.useBackgroundDetectionMixin) {
            mixins.push(backgroundCommon.mixins.backgroundDetectionMixin);
        }
        /**
         * @class components.MediaContainer
         * @extends {core.skinBasedComp}
         */
        return {
            displayName: options.displayName || 'MediaContainer',
            mixins,
            propTypes: {
                style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
                compDesign: santaComponents.santaTypesDefinitions.Component.compDesign,
                compProp: santaComponents.santaTypesDefinitions.Component.compProp,
                isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
                isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism,
                containerStyle: santaComponents.santaTypesDefinitions.CompDesign.containerStyle,
                // Column props
                bgStyle: PropTypes.object,
                rootStyle: PropTypes.object,
                inlineParentStyle: PropTypes.object,
                inlineStyle: PropTypes.object,
                isMobileResponsive: PropTypes.bool
            },
            statics: {
                compSpecificIsDomOnlyOverride: () => _.get(options, 'isDomOnlyUpdateAllowed', true),
                behaviors: mediaCommon.mediaBehaviors.fill
            },

            getDefaultSkinName() {
                return 'wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer';
            },

            getSkinProperties() { // eslint-disable-line complexity
                const width = (this.props.rootStyle || this.props.style).width;
                const bgStyle = this.props.bgStyle || mediaCommon.defaultMediaStyle;

                const inlineParentStyle = this.props.inlineParentStyle ?
                    _.clone(this.props.inlineParentStyle) :
                    _.omit(bgStyle, 'maxWidth', 'minHeight');

                const inlineStyle = this.props.inlineStyle ?
                    _.clone(this.props.inlineStyle) :
                    {
                        width,
                        position: 'absolute',
                        top: 0,
                        bottom: 0
                    };
                const isMobileResponsive = Boolean(this.props.isMobileResponsive);
                const {isMeshLayoutMechanism} = this.props;
                const containerStyle = _.clone(this.props.containerStyle);

                if (isMeshLayoutMechanism) {
                    _.assign(inlineStyle, {position: 'relative'});
                    _.assign(inlineParentStyle, {position: 'relative'});
                    _.assign(containerStyle, {position: 'relative', height: '100%', width: '100%'});
                }

                const childrenRendererProps = {contentArea: this.props.contentArea};
                if (isMeshLayoutMechanism && this.props.leavingChildrenIds && this.lastMeshParams) {
                    childrenRendererProps.overrides = {
                        components: [
                            ...this.props.meshParams.components,
                            ..._.filter(this.lastMeshParams.components, ({id}) => _.includes(this.props.leavingChildrenIds, id))
                        ]
                    };
                }

                if (options.fitToContentHeight) {
                    _.set(childrenRendererProps, ['overrides', 'fitToContentHeight'], true);
                }

                const mediaContainerChildren = this.getChildrenRenderer(childrenRendererProps);

                this.lastMeshParams = this.props.meshParams;

                return {
                    '': _.assign(
                        {style: getRootStyle(this.props.rootStyle, isMeshLayoutMechanism)},
                        this.props.contentArea && {'data-content-width': this.props.contentArea.width},
                        isMobileResponsive && {'data-mobile-responsive': true},
                        isMeshLayoutMechanism && {'data-is-mesh': true}),
                    background: this.createFillLayers({bgStyle}),
                    container: {
                        style: getContainerStyle(containerStyle)
                    },

                    inlineContentParent: {
                        style: inlineParentStyle
                    },

                    inlineContent: {
                        children: mediaContainerChildren,
                        style: inlineStyle
                    }
                };
            }
        };
    }

    return {
        createMediaContainer
    };
});
